#myInfo {
  ion-grid {
    padding:0;

    ion-row {
      padding:0 20px;

      &.id {
        ion-col {
          padding-top:30px;
          p {
            font-weight: 500;
            font-size: 16px;
          }
        }
      }

      &.pw {
        margin-top:20px;
        padding:0 20px 30px 20px;
        ion-col {
          padding:0;
          ion-input {
            font-size:16px;
            font-weight: 500;
            border:1px solid #eaeaea;
            color: #000;
            --placeholder-color:#b2b2b2;
          }
        }
      }

      &.btn_box {
        padding:30px 0 60px 0 ;
        margin:0 20px;
        border-top:1px solid #eaeaea;
        ion-col {
          padding:0;
          ion-button {
            height:50px;
            --background:#e0644a;
            font-size:14px;
            color: #fff;
            --box-shadow: none;
          }
        }
      }
    }
  }
}
