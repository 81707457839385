#myPage {
  ion-grid {
    padding:0;
    ion-col {
      padding:0;
    }
  }

  .message {
    font-weight: 400;
    font-size: 20px;
    color: #b4653e;
    > div {
      padding-top: 30px;
      padding-left: 20px;
      > span {
        font-weight: 700;
      }
    }
  }

  .my_data {
    text-align: center;
    margin:20px 0 30px 0;
    padding-left: 30px;
    padding-right: 30px;
    ion-col {
      > div {
        font-weight: 400;
        font-size:14px;
        color:#767676;
        > p {
          font-weight: 700;
          font-size:22px;
          color:#262626;
        }
      }
    }
  }

  .line::before {
    content: "";
    float: left;
    height: 37px;
    border: solid 1px #eaeaea;
    margin-top: 8px;
  }

  ion-list {
    --border-color: #eaeaea;
    padding: 10px 0;
    ion-item {
      padding: 0 20px 0 20px;
      --background-focused : none;
      --background-focused-opacity : none;
      ion-router-link {
        background: none;
        font-weight: 700;
        font-size: 16px;
        color: #767676;
        padding: 0 10px 0 0;
        margin: 0;
      }
      &::part(native) {
        padding-left:0;
      }
      &:last-child {
        --border-width:0;
      }
    }
  }

  .grey_box {
    height: 8px;
    background-color: #f5f5f5;
  }

  .logout {
    padding: 16px 20px 60px 20px;
    ion-router-link {
      font-weight: 700;
      font-size: 16px;
      color: #767676;
    }
  }

  .alert {
    font-weight: 500;
    font-size: 12px;
    color:#e0644a;
  }

}
