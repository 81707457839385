#recipe_page {
  ion-grid {
    padding:0;

    ion-row {

      ion-col {
        padding:0;

        .recipe_tit {
          display: block;
          padding:20px 0 30px 0;
          border-bottom: 1px solid #eaeaea;
          font-size:20px;
          font-weight: bold;
          color: #262626;
        }
      }

      ion-list {
        padding:0 0 102px 0;
        margin: 30px 0;
      }
    }

    ion-row + ion-row {
      padding:0 20px;
    }
  }
}
