#notice {
  ion-grid {
    padding:0;
    ion-row {
      padding:0;
      ion-col {
        padding:0;
      }
    }
  }
  .open {
    position: absolute;
    bottom:0;
    padding:20px 0 60px;
    width: 100%;
    text-align: center;
    border-top: 1px solid #eaeaea;
    background-color:#ffffff;

    > button {
      display: inline-block;
      width: 40px;
      height: 40px;
      background: url("/assets/icon/btn_review_more.png") no-repeat 0 0;
      color: transparent;
    }
  }
  .notice_not_exist {
    padding:152px 0;

    ion-icon {
      margin-bottom: 10px;
    }
    ion-text {
      display: block;
      font-size: 14px;
    }
  }
}


