.option_box1 > .option_value {
  font-size: 16px;
  font-weight: 500;
  // width: 250px;
  text-align: right;
  color: #262626;
}

.pay_box {
  .delivery_status {
    display: inline-block;
    position: relative;
    padding-left: 10px;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #6ca437;

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -4px;
      width: 1px;
      height: 8px;
      background: url('/assets/icon/line.jpg') no-repeat 0 0;
      background-size: 100%;
    }
  }

  .invoice {
    display: inline-block;
    position: relative;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 500;
    color: #262626;
  }
}
