#login_page {

  ion-grid {
    padding:0;
    ion-list {
      width:100%;
      padding:30px 20px 0 20px;

      ion-item {
        border:1px solid #eaeaea;

        &::part(native) {
          padding-left:10px;
        }
      }

      ion-item + ion-item {
        margin-top:3px;
      }

      ion-text {
        display: block;
        padding:10px 0;
        p {
          padding:0;
          font-size:14px;
          font-weight: 500;
          color: #e0644a;
        }
      }

      ion-col {
        padding:10px 0;
      }

      ion-button {
        height: 50px;
        --background:#e0644a;
        --box-shadow: none;
        color: #fff;
      }

      .whiteThem {
        padding:0;
        &.colorThem {
          .button-solid {
            --background: #fee500;
            --color: #262626;
            --box-shadow: none;
          }
          .naver.button-solid {
            --background: #03c75a;
            --color: #ffffff;
            color: #ffffff;

            &.naver::before {
              background-image: url("/assets/icon/naver_white.png");
            }
          }
          .google.button-solid {
            --background: #ffffff;
            --color: #262626;
          }
          .apple.button-solid {
            --background: #050708;
            --color: #ffffff;
            color: #ffffff;
            &.apple::before {
              background-image: url("/assets/icon/apple_white.png");
            }
          }
          .apple {
            margin-bottom: 60px;
          }
        }
        .button-solid {
          --background: #ffffff;
          --color: #262626;
          --box-shadow: none;
        }
        ion-button {
          position: relative;
          margin:10px 0 0 0;
          height: 50px;
          --border-radius: 0px;
          border: solid 1px #eaeaea;
          font-size: 14px;
          font-weight: 500;
          color: #262626;

          &::before {
            content: "";
            position: absolute;
            left: 30px;
            top: 50%;
            margin-top:-9px;
            width: 15px;
            height: 18px;
            background-image: url("/assets/icon/kakao.png");
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: 0 0;
            z-index: 10;
          }

          &.naver::before {
            background-image: url("/assets/icon/naver.png");
          }

          &.google::before {
            background-image: url("/assets/icon/google.png");
          }

          &.apple::before {
            background-image: url("/assets/icon/apple.png");
          }
        }
        ion-img {
          width: 18px;
          height: 17px;
        }
        .apple {
          margin-bottom: 60px;
        }
      }

      .option {
        text-align: center;
        margin-bottom: 15px;
        li {
          display: inline-block;
          margin-left:17px;
          &:first-child {
            margin-left:0;

            span {
              &::before {
                content: none;
              }
            }
          }
          ion-router-link {
            span {
              font-size: 14px;
              font-weight: 500;
              color: #767676;
              &::before {
                content: "|";
                margin-right: 10px;
                color: #eaeaea;
              }
            }
          }
        }
      }

      input[type='checkbox'] {
        display:none;
      }

      input[type='checkbox'] + label {
        width: 100%;
        font-size:16px;
        font-weight: 500;
        color: #262626;
      }

      input[type='checkbox'] + label::before {
        content: "";
        display:inline-block;
        cursor: pointer;
        height: 24px;
        line-height: 24px;
        padding-left:24px;
        margin-bottom:-2px;
        margin-right: 5px;
        vertical-align: bottom;
        background-image: url('/assets/icon/ico_check_on_off_type2.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: 0 0;
      }

      input[type='checkbox']:checked + label::before {
        background-position: 0 -24px;
      }
    }
  }
}
