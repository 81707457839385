.toastui-editor-contents {
  font-size: 16px !important;

  *:not(table) {
    line-height: 160% !important;
    box-sizing: content-box !important;
  }

  i,
  cite,
  em,
  var,
  address,
  dfn {
    font-style: italic !important;
  }

  strong {
    font-weight: bold !important;
  }

  p {
    margin: 10px 0 !important;
    color: #222 !important;
  }

  > h1:first-of-type,
  > div > div:first-of-type h1 {
    margin-top: 14px !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold !important;
    color: #222 !important;
  }

  h1 {
    font-size: 24px !important;
    line-height: 28px !important;
    border-bottom: 3px double #999 !important;
    margin: 52px 0 15px 0 !important;
    padding-bottom: 7px !important;
  }

  h2 {
    font-size: 22px !important;
    line-height: 23px !important;
    border-bottom: 1px solid #dbdbdb !important;
    margin: 20px 0 13px 0 !important;
    padding-bottom: 7px !important;
  }

  h3 {
    font-size: 20px !important;
    margin: 18px 0 2px !important;
  }

  h4 {
    font-size: 18px !important;
    margin: 10px 0 2px !important;
  }

  h3,
  h4 {
    line-height: 18px !important;
  }

  h5 {
    font-size: 16px !important;
  }

  h6 {
    font-size: 14px !important;
  }

  h5,
  h6 {
    line-height: 17px !important;
    margin: 9px 0 -4px !important;
  }

  del {
    color: #999 !important;
  }

  blockquote {
    margin: 14px 0 !important;
    border-left: 4px solid #e5e5e5 !important;
    padding: 0 16px !important;
    color: #999 !important;
  }

  blockquote p,
  blockquote ul,
  blockquote ol {
    color: #999 !important;
  }

  blockquote > :first-child {
    margin-top: 0 !important;
  }

  blockquote > :last-child {
    margin-bottom: 0 !important;
  }

  pre,
  code {
    font-family: Consolas, Courier, 'Apple SD 산돌고딕 Neo', -apple-system, 'Lucida Grande', 'Apple SD Gothic Neo', '맑은 고딕',
      'Malgun Gothic', 'Segoe UI', '돋움', dotum, sans-serif !important;
    border: 0 !important;
    border-radius: 0 !important;
  }

  pre {
    margin: 2px 0 8px !important;
    padding: 18px !important;
    background-color: #f4f7f8 !important;
  }

  code {
    color: #c1798b !important;
    background-color: #f9f2f4 !important;
    padding: 2px 3px !important;
    letter-spacing: -0.3px !important;
    border-radius: 2px !important;
  }

  pre code {
    padding: 0 !important;
    color: inherit !important;
    white-space: pre-wrap !important;
    background-color: transparent !important;
  }

  img {
    margin: 4px 0 10px !important;
    box-sizing: border-box !important;
    vertical-align: top !important;
    max-width: 100% !important;
  }

  table {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin: 12px 0 14px !important;
    color: #222 !important;
    width: auto !important;
    border-collapse: collapse !important;
    box-sizing: border-box !important;
  }

  table th,
  table td {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    padding: 5px 14px 5px 12px !important;
    height: 32px !important;
  }

  table th {
    background-color: #555 !important;
    font-weight: 300 !important;
    color: #fff !important;
    padding-top: 6px !important;
  }

  th p {
    margin: 0 !important;
    color: #fff !important;
  }

  td p {
    margin: 0 !important;
    padding: 0 2px !important;
  }

  td.toastui-editor-cell-selected {
    background-color: #d8dfec !important;
  }

  th.toastui-editor-cell-selected {
    background-color: #908f8f !important;
  }

  ul,
  menu,
  ol,
  dir {
    display: block !important;
    list-style-type: none !important;
    padding-left: 24px !important;
    margin: 6px 0 10px !important;
    color: #222 !important;
  }

  ol {
    list-style-type: none !important;
    counter-reset: li !important;
  }

  ol > li {
    counter-increment: li !important;
  }

  ul > li::before,
  ol > li::before {
    display: inline-block !important;
    position: absolute !important;
  }

  ul > li::before {
    content: '' !important;
    margin-top: 6px !important;
    margin-left: -17px !important;
    width: 5px !important;
    height: 5px !important;
    border-radius: 50% !important;
    background-color: #ccc !important;
  }

  ol > li::before {
    content: '.' counter(li) !important;
    margin-left: -28px !important;
    width: 24px !important;
    text-align: right !important;
    direction: rtl !important;
    color: #aaa !important;
  }

  ul ul,
  ul ol,
  ol ol,
  ol ul {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ul li,
  ol li {
    position: relative !important;
  }

  ul p,
  ol p {
    margin: 0 !important;
  }

  hr {
    border-top: 1px solid #eee !important;
    margin: 16px 0 !important;
  }

  a {
    text-decoration: underline !important;
    color: #4b96e6 !important;
  }

  a:hover {
    color: #1f70de !important;
  }

  .image-link {
    position: relative !important;
  }

  .image-link:hover::before {
    content: '' !important;
    position: absolute !important;
    width: 30px !important;
    height: 30px !important;
    right: 0px !important;
    border-radius: 50% !important;
    border: 1px solid #c9ccd5 !important;
    background: #fff
      url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBzdHJva2U9IiM1NTUiIHN0cm9rZS13aWR0aD0iMS41Ij4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNNy42NjUgMTUuMDdsLTEuODE5LS4wMDJjLTEuNDg2IDAtMi42OTItMS4yMjgtMi42OTItMi43NDR2LS4xOTJjMC0xLjUxNSAxLjIwNi0yLjc0NCAyLjY5Mi0yLjc0NGgzLjg0NmMxLjQ4NyAwIDIuNjkyIDEuMjI5IDIuNjkyIDIuNzQ0di4xOTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMDAwIC00NTgxKSB0cmFuc2xhdGUoOTk1IDQ1NzYpIHRyYW5zbGF0ZSg1IDUpIHNjYWxlKDEgLTEpIHJvdGF0ZSg0NSAzNy4yOTMgMCkiLz4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTIuMzI2IDQuOTM0bDEuODIyLjAwMmMxLjQ4NyAwIDIuNjkzIDEuMjI4IDIuNjkzIDIuNzQ0di4xOTJjMCAxLjUxNS0xLjIwNiAyLjc0NC0yLjY5MyAyLjc0NGgtMy44NDVjLTEuNDg3IDAtMi42OTItMS4yMjktMi42OTItMi43NDRWNy42OCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwMDAgLTQ1ODEpIHRyYW5zbGF0ZSg5OTUgNDU3NikgdHJhbnNsYXRlKDUgNSkgc2NhbGUoMSAtMSkgcm90YXRlKDQ1IDMwLjk5NiAwKSIvPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K)
      no-repeat !important;
    background-position: center !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08) !important;
    cursor: pointer !important;
  }

  .task-list-item {
    border: 0 !important;
    list-style: none !important;
    padding-left: 24px !important;
    margin-left: -24px !important;
  }

  .task-list-item::before {
    background-repeat: no-repeat !important;
    background-size: 18px 18px !important;
    background-position: center !important;
    content: '' !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
    border-radius: 2px !important;
    height: 18px !important;
    width: 18px !important;
    position: absolute !important;
    left: 0 !important;
    top: 1px !important;
    cursor: pointer !important;
    background: transparent
      url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iI0ZGRiIgc3Ryb2tlPSIjQ0NDIj4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAzMCAtMjk2KSB0cmFuc2xhdGUoNzg4IDE5MikgdHJhbnNsYXRlKDI0MiAxMDQpIj4KICAgICAgICAgICAgICAgICAgICA8cmVjdCB3aWR0aD0iMTciIGhlaWdodD0iMTciIHg9Ii41IiB5PSIuNSIgcng9IjIiLz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==) !important;
  }

  .task-list-item.checked::before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzRCOTZFNiI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTE2IDBjMS4xMDUgMCAyIC44OTUgMiAydjE0YzAgMS4xMDUtLjg5NSAyLTIgMkgyYy0xLjEwNSAwLTItLjg5NS0yLTJWMkMwIC44OTUuODk1IDAgMiAwaDE0em0tMS43OTMgNS4yOTNjLS4zOS0uMzktMS4wMjQtLjM5LTEuNDE0IDBMNy41IDEwLjU4NSA1LjIwNyA4LjI5M2wtLjA5NC0uMDgzYy0uMzkyLS4zMDUtLjk2LS4yNzgtMS4zMi4wODMtLjM5LjM5LS4zOSAxLjAyNCAwIDEuNDE0bDMgMyAuMDk0LjA4M2MuMzkyLjMwNS45Ni4yNzggMS4zMi0uMDgzbDYtNiAuMDgzLS4wOTRjLjMwNS0uMzkyLjI3OC0uOTYtLjA4My0xLjMyeiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwNTAgLTI5NikgdHJhbnNsYXRlKDc4OCAxOTIpIHRyYW5zbGF0ZSgyNjIgMTA0KSIvPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K) !important;
  }
}
