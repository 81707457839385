#pay_page {
  ion-grid {
    padding:0;
    background-color:#f5f5f5;

    ion-accordion-group {
      padding-top:30px;
      padding-right:20px;
      padding-bottom:0;
      padding-left: 20px;
      background-color: #fff;

      &.arrow_box {
        padding-bottom:0;
        margin-top:8px;
      }
    }

    ion-icon {
      &.ion-accordion-toggle-icon {
        color:#000;
      }
    }

    ion-item {
      --inner-padding-end:0;
      --background-hover-opacity:0;
      &::part(native) {
        padding-left:0;
      }
      &.tit {
        > ion-label {
          font-size: 20px;
          font-weight: 500;
          color: #000;
        }
      }
    }

    ion-list {
      padding:0;
    }

    ion-row {
      background-color:#fff;
      padding:0 20px;
      margin-top:8px;

      &.p_n {
        padding:0;

        &.b_l {
          border-top:1px solid #eaeaea;
        }
      }

      ion-col {
        padding:0;
      }

      .order_info {
        position:relative;
        padding:30px 0;

        strong {
          font-size: 20px;
          font-weight: 500;
          color: #000;
        }

        ion-input {
          margin-top:20px;
          border:1px solid #eaeaea;
          font-size:16px;
          font-weight: 500;
          --color:#767676;
        }
        ion-input + ion-input {
          margin-top:10px;
        }

        ion-router-link {
          position:absolute;
          right:0;
          top:34px;
          background: none;
          padding:0;
          margin: 0;
          font-size: 14px;
          font-weight: 500;
          text-decoration: underline;
          color: #e0644a;
        }

        ion-text {
          font-size:16px;
          font-weight: 500;
          color: #262626;
          span {
            display:block;
            margin-top:10px;

            &:first-child {
              margin-top:20px;
            }

            &.d_name {
              font-weight: bold;
            }
          }

          address {
            margin-top:10px;
          }
        }

        .radio_box {
          margin-top:17px;
          &:first-child {
            margin-top:20px;
          }

          .pay_tit {
            font-size:16px;
            font-weight: 500;
            color: #262626;
          }
        }
        .virtual_info_box {
          padding: 10px;

          .virtual_due {
            font-size: 25px;
            font-weight: 500;
            color: #e0644a;
            text-align: left;
            > em {
              font-size: 20px;
              font-weight: bold;
            }
          }
          .virtual_info {
            padding: 7px;
            > li {
              margin-bottom: 5px;
              font-size: 15px;
              color: #5f5f5f;
            }
          }
        }
      }

      .total_type1 {
        .option_box1 {
          display: flex;
          justify-content: space-between;

          > strong {
            // float: left;
            font-size: 16px;
            font-weight: 500;
            color: #262626;
            min-width: 65px;
          }

          // &::after {
          //   content: '';
          //   display: block;
          //   clear: both;
          // }
        }
        .modam_price {
          // float: right;
          font-size: 16px;
          font-weight: 500;
          min-width: 125px;
          text-align: right;
          color: #262626;
          > em {
            font-size: 18px;
            font-weight: bold;
          }
        }

        .option_box2 {
          display: flex;
          justify-content: space-between;
          position: relative;
          > span {
            // float: left;
            font-size: 16px;
            font-weight: 500;
            color: #767676;
            padding-left: 15px;

            &::before {
              content: ' - ';
              position: absolute;
              left: 4px;
            }
          }
        }

        &.type2 {
          padding-top:17px;
          padding-bottom:20px;
          .option_box1 {
            strong {
              display: inline-block;
              margin-top:5px;
              font-size: 16px;
              font-weight: bold;
            }
          }

          .modam_price {
            font-size:16px;
            font-weight:500;
            color: #e0644a;
            > em {
              font-size:22px;
              font-weight: bold;
            }
          }
        }
      }

      input[type='radio'] {
        display:none;
      }

      input[type='radio'] + label {
        width: 100%;
        font-size:16px;
        font-weight: 500;
        color: #767676;
      }

      input[type='radio'] + label::before {
        content: "";
        display:inline-block;
        cursor: pointer;
        height: 24px;
        line-height: 24px;
        padding-left:24px;
        margin-bottom:-2px;
        margin-right: 5px;
        vertical-align: bottom;
        background-image: url('/assets/icon/ico_check_on_off.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: 0 0;
      }

      input[type='radio']:checked + label::before {
        background-position: 0 -24px;
      }

      .submit_wrap {
        padding-bottom:60px;
        .submit {
          margin:0;
          height:50px;
          font-size:16px;
          font-weight: 500;
          --background:#e0644a;
          --box-shadow: none;
          color:#fff;
        }
      }
    }
  }
}
