#recipe_page {

  ion-card {
    display:inline-block;
    margin:0;
    width: 48%;
    box-shadow: rgb(0 0 0 / 12%) 0px 0px 0px;
    border-radius: 0;

    .card_tit {
      font-size: 16px;
      font-weight: 500;
      color: #262626;
    }

    ion-card-content {
      padding:10px 0 0 0;
    }
  }

  ion-card + ion-card:nth-child(even) {
    margin-left:4%;
  }

  ion-card + ion-card ~ ion-card {
    margin-top:30px;
  }

  .open {
    position: absolute;
    padding:20px 0 20px 0;
    left:-20px;
    right:-20px;
    bottom:0;
    text-align: center;
    border-top: 1px solid #eaeaea;
    background-color:#ffffff;

    > button {
      display: inline-block;
      width: 40px;
      height: 40px;
      background: url("/assets/icon/btn_review_more.png") no-repeat 0 0;
      color: transparent;
    }
  }

}



