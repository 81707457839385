#modam_main {
  ion-grid {
    padding:0;
  }

  .swiper_wrap {
    position: relative;
    padding:0;

    .swiper {
      .swiper-slide {
        display:inline-block;
      }
    }

    .swiper-pagination{
      position: absolute;
      right: 2rem;
      bottom: 2rem;
      padding: 0.3rem 1rem;
      border-radius: 100px;
      background-color: #000;
      opacity: 0.3;
      z-index: 10;
      font-size:12px;
      color:#fff;
    }
  }

  .pd {
    padding:0 20px;
  }

  .product_type1 {
    margin:0 20px;
    padding-top:30px;
    border-top: 1px solid #eaeaea;

    ion-col {
      padding:0;
      > ion-text {
        text-align: left;
        > h3 {
          font-size:16px;
          font-weight: bold;
          color: #262626;
        }
      }
    }
    ion-list {
      margin-top:20px;
      padding:0;
      ion-card + ion-card:nth-child(even) {
        margin-left:4%;
      }
    }
  }

  .border {
    border:none;
  }

  .open {
    margin:10px 20px 0 20px;
    > ion-col {
      position: relative;
      padding:0;

      > ion-button {
        margin:0;
        height:40px;
        font-size:14px;
        font-weight: 500;
        --background:transparent;
        --color: #b2b2b2;
        position:relative;

        .arrow {
          position: relative;

          &::after {
            position: absolute;
            right: -10px;
            top: 5px;
            content: '';
            width: 4px;
            height: 4px;
            border-top: 1px solid #b2b2b2;
            border-right: 1px solid #b2b2b2;
            transform: rotate(400deg);
          }
        }

        &::part(native) {
          border:1px solid #eaeaea;
          --box-shadow:none
        }
      }
    }
  }

  .banner {
    margin-top:30px;

    ion-col {
      margin:0;
      padding:0;
    }
  }

  .product_wrap {
    margin: 0 20px;

    ion-col {
      padding: 0;
      > ion-text {
        text-align: left;
        > h3 {
          font-size: 16px;
          font-weight: bold;
          color: #262626;
        }
      }
    }
    ion-list {
      margin: 20px 0 60px 0;
      ion-card + ion-card:nth-child(even) {
        margin-left: 4%;
      }

      ion-card + ion-card ~ ion-card {
        margin-top: 30px;
      }
    }
  }
}
