.tabRow {
  padding:15px 0 !important;
  align-items: center;
  border-bottom:1px solid #eaeaea;
  ion-col {
    padding:0;
    height:100%;
    text-align: center;
    font-size: 15px;
    color: #262626;
  }

  > ion-col:first-child {
    margin-left:2rem;
  }

  > ion-col:last-child {
    //margin-right:-0.5rem;
  }
}

.searchIcon {
  display: inline-block;
  > ion-img {
    display:inline-block;
    vertical-align: top;
    width: 20px;
    height:20px;
  }
}
