#inquiry {
  ion-grid {
    ion-row {
      ion-col {
        padding:0;
        &.add_box {
          padding: 30px 0 20px 0;
          .col_span {
            display:inline-block;
            vertical-align: middle;
          }

          ion-img {
            width:100px;
            height:100px;
          }


          .price_box {
            margin-left:20px;
            span {
              font-size: 12px;
              font-weight: 500;
              color: #b2b2b2;
            }
            span + span {
              margin-left:5px;
              &::before {
                content: "|";
                margin-right: 5px;
              }
            }

            strong {
              display: block;
              font-size:14px;
              font-weight: bold;
              color: #262626;
            }

            .price {
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
