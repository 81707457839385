
#defaultHeader {
  background-color: #fff;
  padding-bottom: 1px;
  border-bottom: 1px solid #eaeaea;

  &:after {
    background-image: none;
  }


  ion-toolbar {
    --border-width:0;
  }

  ion-title {
    padding:0;
    text-align: center;
  }

  .login-logo {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .ion-no-padding {
    position: relative;
    padding:0.5rem 0.5rem 0 0.5rem;

    ion-buttons {


      ion-button {
        width:100%;
        height:100%;

        //ion-back-button {
        //  background-image: url('/assets/icon/ico_previous.png');
        //}
      }
    }

    .btn_wrap {
      position: absolute;
      right:0;
      ion-button::part(native) {
        padding:0;
        color:#fff;
      }
    }

    .cart {
      position: relative;
      > ion-button {
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        width:20px;
        height:20px;
        border-radius: 50%;
        font-size:12px;
        line-height: 1.09;
        background-color: #e0644a;
        color:#fff;
      }
    }
  }

  ion-back-button {
    position: absolute;
  }
}
