#signup_page {
  ion-grid {
    padding: 0;

    ion-button {
      margin: 0;
    }

    ion-list {
      padding: 30px 20px 0px 20px;
      ion-item {
        position: relative;
        margin-top: 10px;
        --inner-padding-end: 0;
        &::part(native) {
          padding-left: 0;
        }
        &:first-child {
          margin-top: 0;
        }

        ion-input {
          --padding-end: 10px;
          --padding-start: 10px;
          border: 1px solid #eaeaea;
          --placeholder-color: #b2b2b2;
        }

        ion-icon {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translate(0, -50%);
        }

        ion-button {
          margin: 0 0 0 5px;
          height: 100%;
          font-size: 14px;
          font-weight: 500;
          border: 1px solid #eaeaea;
          color: #767676;

          --box-shadow: none;
          --background: #f5f5f5;
        }

        .code_time {
          position: absolute;
          right: 8px;
        }
      }

      ion-text {
        font-size: 14px;
        font-weight: 500;
        color: #e0644a;
        p {
          padding-top: 5px;
        }
      }

      ion-row {
        &.row_c {
          margin-top: 10px;
          padding-bottom: 20px;
          border-bottom: 1px solid #eaeaea;
          &.row_txt {
            ion-col {
              ion-text {
                color: #b2b2b2;
              }
            }
          }
        }

        &.radio_wrap {
          padding: 30px 0;
          div {
            margin-top: 20px;
            &:first-child {
              margin-top: 0;
            }
            label {
              font-size: 14px;
              font-weight: 500;
              &:first-child {
                display: inline-block;
                width: 33%;
                color: #050708;
              }
            }

            &.radio_box {
              display: inline-block;
              margin-top: 0;
              margin-left: 20px;
              &:first-child {
                margin-left: 0;
              }

              > label {
                color: #767676;
              }
            }
          }
        }

        &.checked_wrap {
          border-top: 1px solid #eaeaea;
          padding: 29px 0 41px 0;

          .checked_box {
            margin-top: 20px;
            &:first-child {
              margin-top: 0;
            }
          }

          > label {
            font-size: 14px;
            font-weight: 500;
            color: #050708;
          }

          ion-router-link {
            margin-left: 3px;
            font-size: 12px;
            font-weight: 500;
            text-decoration: underline;
            color: #b2b2b2;
          }
        }
        ion-col {
          padding: 0;
          margin: 0;
        }

        &.btn_rt {
          ion-text {
            display: inline-block;
            margin: 10px 0;
          }
          ion-button {
            font-size: 14px;
            font-weight: 500;
            border: 1px solid #eaeaea;
            color: #767676;

            --box-shadow: none;
            --background: #f5f5f5;
          }
        }
      }

      input[type='checkbox'] {
        display: none;
      }

      input[type='checkbox'] + label {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        color: #262626;
      }

      input[type='checkbox'] + label::before {
        content: '';
        display: inline-block;
        cursor: pointer;
        height: 24px;
        line-height: 24px;
        padding-left: 24px;
        margin-bottom: -2px;
        margin-right: 5px;
        vertical-align: bottom;
        background-image: url('/assets/icon/ico_check_on_off_type2.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: 0 0;
      }

      input[type='checkbox']:checked + label::before {
        background-position: 0 -24px;
      }

      input[type='radio'] + label {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        vertical-align: middle;
        color: #262626;
      }

      input[type='radio'],
      input[type='radio']:checked {
        appearance: none;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border-radius: 100%;
        border: 1px solid #eaeaea;
        vertical-align: middle;
      }

      input[type='radio'] {
        background-color: transparent;
      }

      input[type='radio']:checked {
        position: relative;
      }

      input[type='radio']:checked:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 10px;
        height: 10px;
        margin: -5px 0 0 -5px;
        border-radius: 100%;
        background-color: #e0644a;
      }

      .btn_box {
        text-align: center;
        padding-bottom: 60px;

        ion-button {
          height: 50px;
          font-size: 14px;
          font-weight: 500;
          color: #fff;
          --box-shadow: none;
          --background: #e0644a;
        }
      }
    }
  }
}

.agreement-modal {
  .content-wrapper {
    overflow: scroll;
    padding: 12px;
  }
}
