#myInfoDetail {
  ion-grid {
    padding:0;

    ion-row {
      padding:0 20px;

      &.id {
        ion-col {
          padding-top:30px;
          padding-bottom: 20px;
          p {
            font-weight: 500;
            font-size: 16px;
          }
        }
      }

      &.input {
        padding:0 20px 10px 20px;
        ion-col {
          padding:0;
          ion-input {
            font-size:16px;
            font-weight: 500;
            border:1px solid #eaeaea;
            color: #767676;
            --placeholder-color:#b2b2b2;
          }
        }
      }

      &.zipcode {
        padding:0 20px 10px 20px;
        ion-col {
          padding:0;
          ion-input {
            position: relative;
            width: 69%;
            font-size:16px;
            font-weight: 500;
            border:1px solid #eaeaea;
            color: #767676;
            --placeholder-color:#b2b2b2;
          }
          ion-button {
            border: 1px solid #eaeaea;
            position: absolute;
            left: 70%;
            margin-top: -45px;
            width: 30%;
            height: 44px;
            --background:#f5f5f5;
            font-size:14px;
            color: #767676;
            --box-shadow: none;
          }
        }
      }

      &.mobile {
        padding:0 20px 10px 20px;
        ion-col {
          padding:0;
          ion-input {
            position: relative;
            width: 79%;
            font-size:16px;
            font-weight: 500;
            border:1px solid #eaeaea;
            color: #767676;
            --placeholder-color:#b2b2b2;

            .code_time {
              position: absolute;
              right:8px;
            }
          }
          ion-button {
            border: 1px solid #eaeaea;
            position: absolute;
            left: 80%;
            margin-top: -45px;
            width: 20%;
            height: 44px;
            --background:#f5f5f5;
            font-size:14px;
            color: #767676;
            --box-shadow: none;
          }
        }
      }

      &.text_box {
        padding-bottom: 20px;
        p {
          font-weight: 500;
          font-size: 14px;
          color: #b2b2b2;
        }
      }

      &.modify_btn {
        padding-top: 30px;
        ion-col {
          padding:0;
          ion-button {
            height:50px;
            --background:#e0644a;
            font-size:14px;
            color: #fff;
            --box-shadow: none;
          }
        }
      }

      &.withdrawal_btn {
        padding-bottom:60px;
        ion-col {
          padding:0;
          ion-button {
            border: 1px solid #eaeaea;
            height:50px;
            --background:#fff;
            font-size:14px;
            color: #000;
            --box-shadow: none;
          }
        }
      }

      &.radio_wrap {
        padding:30px 0;
        margin:0 20px;
        border-top:1px solid #eaeaea;
        border-bottom:1px solid #eaeaea;
        div {
          margin-top:20px;
          &:first-child {
            margin-top:0;
          }
          label {
            font-size: 14px;
            font-weight: 500;
            &:first-child {
              display: inline-block;
              width: 33%;
              color: #050708;
            }
          }

          &.radio_box {
            display: inline-block;
            margin-top:0;
            margin-left:20px;
            &:first-child {
              margin-left:0;
            }

            > label {
              color: #767676;
            }
          }
        }
      }

      &.btn_rt {

        margin-bottom:10px;

        ion-text {
          display: inline-block;
          margin:10px 0;
        }
        ion-button {
          font-size:14px;
          font-weight: 500;
          border:1px solid #eaeaea;
          color: #767676;

          --box-shadow: none;
          --background: #f5f5f5;
        }
      }

    }

    ion-col {
      padding:0;

      ion-text {
        font-size:14px;
        font-weight: 500;
        color: #e0644a;
        p {
          padding-bottom:5px;
        }
      }
    }

    input[type='radio'] + label {
      width: 100%;
      font-size:16px;
      font-weight: bold;
      vertical-align: middle;
      color: #262626;
    }

    input[type='radio'],
    input[type='radio']:checked {
      appearance: none;
      width: 20px;
      height: 20px;
      margin-right:5px;
      border-radius: 100%;
      border:1px solid #eaeaea;
      vertical-align: middle;
    }

    input[type='radio'] {
      background-color: transparent;
    }

    input[type='radio']:checked {
      position: relative;
    }

    input[type='radio']:checked:before {
      content: "";
      position:absolute;
      left:50%;
      top:50%;
      width: 10px;
      height: 10px;
      margin:-5px 0 0 -5px;
      border-radius: 100%;
      background-color:#e0644a;
    }
  }
}
