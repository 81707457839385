ion-card.product_card {
  display: inline-block;
  margin: 0;
  width: 48%;
  box-shadow: rgb(0 0 0 / 12%) 0px 0px 0px;
  border-radius: 0;

  .meat_con {
    position: relative;
    padding: 0;

    ion-row:first-child.meat_info {
      margin-top: 10px;
      > ion-col {
        font-size: 12px;
        font-weight: 500;
        color: #b2b2b2;
        span + span {
          margin-left: 5px;
          &::before {
            content: '|';
            margin-right: 5px;
          }
        }
      }
    }

    ion-col {
      text-align: left;
    }

    .meat_tit {
      margin-top: 10px;
      font-size: 14px;
      font-weight: bold;
      color: #262626;
      max-height: 42px;

      > ion-col {
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-word;

        display: -webkit-box;
        -webkit-line-clamp: 2; // 원하는 라인수
        -webkit-box-orient: vertical;
      }
    }

    .meat_price {
      font-size: 16px;
      font-weight: 500;
      color: #262626;
      > ion-text {
        font-weight: bold;
        &.outOf {
          color: #b2b2b2;
        }
      }
    }

    .review_con {
      position: absolute;
      bottom: 0;
      padding-top: 13px;
      ion-img {
        display: inline-block;
        width: 14px;
        height: 13px;
      }

      ion-col:first-child {
        > ion-text {
          display: inline-block;
          margin-left: 3px;
          vertical-align: text-bottom;
          font-size: 12px;
          color: #b2b2b2;
        }
      }

      ion-col + ion-col {
        margin-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: right;
        font-size: 12px;
        color: #e0644a;
      }
    }
  }

  ion-router-link {
    position: relative;
  }

  .tit_price_box {
    height: 100px;
    position: relative;
  }

  .cart {
    position: absolute;
    right: 12px;
    bottom: 0px;
    width: 44px;
    height: 44px;
    background-image: url('/assets/icon/cart_on_off.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 0 -42px;
    z-index: 12;
    &.cartOn {
      background-position: 0 0;
    }
  }
}
