#cart {
  ion-grid {
    padding:0;
  }

  ion-col {
    padding:0;
    margin:0;
  }

  ion-row {
    padding:0 20px;
  }

  button {
    padding:0;
    background-color: transparent;
  }

  .cart_type1 {
    padding-top:28px;
    padding-bottom:20px;
    border-bottom: 1px solid #eaeaea;

    .check_remove_wrap {
      button {
        float:right;
        margin-top:2px;
        font-size:16px;
        font-weight: 500;
        color: #b2b2b2;
      }
    }

    &::after{
      content:"";
      display:block;
      clear: both;
    }
  }

  input[type='checkbox'] {
    display:none;
  }

  input[type='checkbox'] + label {
    width: 100%;
    font-size:16px;
    font-weight: 500;
    color: #767676;
  }

  input[type='checkbox'] + label::before {
    content: "";
    display:inline-block;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    padding-left:24px;
    margin-bottom:-2px;
    margin-right: 5px;
    vertical-align: bottom;
    background-image: url('/assets/icon/ico_check_on_off.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
  }

  input[type='checkbox']:checked + label::before {
    background-position: 0 -24px;
  }

  .cart_tit {
    padding-top: 30px;

    strong {
      font-size:20px;
      font-weight: 500;
      vertical-align: middle;
      color: #000000;
    }
    span {
      margin-left:10px;
      font-size: 14px;
      font-weight: bold;
      vertical-align:middle;
      color: #e0644a;
    }
  }

  .pt {
    padding-top: 10px;
  }

  .total_type1 {
    padding-top:5px;
    .option_box1 {
      strong {
        float:left;
        font-size:16px;
        font-weight: 500;
        color: #262626;
      }

      &::after {
        content: "";
        display:block;
        clear: both;
      }
    }
    .modam_price {
      float:right;
      font-size:16px;
      font-weight: 500;
      color: #262626;
      > em {
        font-size:18px;
        font-weight: bold;
      }
    }

    .option_box2 {
      > span {
        float:left;
        font-size:16px;
        font-weight: 500;
        color: #767676;

        &::before {
          content: "- ";
          padding-left:8px;
        }
      }
    }

    &.type2 {
      padding-top:17px;
      .option_box1 {
        strong {
          display: inline-block;
          margin-top:5px;
          font-size: 16px;
          font-weight: bold;
        }
      }

      .modam_price {
        font-size:16px;
        font-weight:500;
        color: #e0644a;
        > em {
          font-size:22px;
          font-weight: bold;
        }
      }
    }
  }

  .submit_wrap {
    padding-top:27px;
    padding-bottom:60px;
    .submit {
      margin:0;
      font-size:16px;
      font-weight: 500;
      --background:#e0644a;
      --background-activated:#e0644a;
      --color-activated:#fff;
      --color:#fff;
    }
  }


  .cart_not_exist {
    padding:152px 0;

    ion-icon {
      margin-bottom: 10px;
    }

    ion-text {
      font-size:18px;
      font-weight: bold;
      color: #333333;

      p + p {
        font-size: 13px;
        color: #888888;
      }
    }

    ion-button {
      margin: 40px 30px;
      height: 40px;
      font-size: 14px;
      font-weight: bold;
      --background: transparent;
      --color: #222222;
      position: relative;

      .arrow {
        position: relative;

        &::after {
          position: absolute;
          right: -10px;
          top: 5px;
          content: '';
          width: 4px;
          height: 4px;
          border-top: 1px solid #b2b2b2;
          border-right: 1px solid #b2b2b2;
          transform: rotate(400deg);
        }
      }
    }
  }

}
