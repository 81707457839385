#pay_page {

  ion-item {
    border-top:1px solid #eaeaea;
    padding-top:20px;
    padding-bottom:20px;
    &:first-child {
      padding-top:0;
      border-top: none;
    }
    &::part(native) {
      padding-left:0;
    }

    ion-row {
      padding:0;
    }

    .col_span {
      display:inline-block;
    }

    ion-img {
      width:80px;
      height:80px;
    }

    ion-text {
      height:100%;
    }

    .pay_box {
      margin-left:15px;
      strong {
        display: block;
        font-size:16px;
        font-weight: 500;
        color: #262626;
      }

      .price {
        display: inline-block;
        margin-top:5px;
        font-size: 16px;
        font-weight: bold;
        color: #262626;
        em {
          font-size:18px;
        }
      }

      .count {
        display: inline-block;
        position: relative;
        padding-left:10px;
        margin-left:10px;
        font-size: 16px;
        font-weight: bold;
        color: #262626;
        em {
          font-size:18px;
        }

        &::before {
          content: "";
          display: inline-block;
          position:absolute;
          left:0;
          top:50%;
          margin-top:-4px;
          width: 1px;
          height:8px;
          background:url('/assets/icon/line.jpg') no-repeat 0 0;
          background-size: 100%;
        }
      }

      .out_of {
        color: #b2b2b2;
      }

      .stock {
        color: #e0644a;
      }
    }
  }
}
