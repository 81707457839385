#defaultFooter {
  background-color: #fafafa;


  &.footer-md {
    &::before {
      height:0;
    }
  }

  .footer_box {
    padding:30px 20px 50px 20px;
    background-color: #fafafa;
    ion-row {
      padding:0;
      background-color: #fafafa;
      ion-col {
        text-align: left;
      }
    }

    .company_phone {
      font-size:16px;
      font-weight: bold;
      color: #262626;
    }

    .info_list {
      > li {
        display:inline-block;
        > a {
          font-size:12px;
          font-weight: 500;
          color: #666666;
        }
      }
      li + li {
        margin-left:5px;
        &::before {
          content: "•";
          margin-right: 5px;
          font-size:12px;
          font-weight: 500;
          color:#666666;
        }
      }
    }

    .company_con {
      margin-top: 25px;
      font-size: 12px;
      color: #b2b2b2;

      strong {
        font-weight: 500;
      }

      a {
         color: #b2b2b2;
      }

      address {
        margin-top:5px;
      }

      p {
        margin-top:5px;
      }
    }
  }
}
