#product {
  ion-grid {
    padding:0;
  }

  .pd {
    padding:0 20px;
  }

  .product_wrap {
    margin:0 20px;

    ion-col {
      padding:0;
      > ion-text {
        text-align: left;
        > h3 {
          font-size:16px;
          font-weight: bold;
          color: #262626;
        }
      }
    }
    ion-list {
      margin: 20px 0 60px 0;
      ion-card + ion-card:nth-child(even) {
        margin-left:4%;
      }

      ion-card + ion-card ~ ion-card {
        margin-top:30px;
      }
    }
  }
}
