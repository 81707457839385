#product_detail {
  ion-content {
    position: relative;
  }
  ion-grid {
    padding: 0;
    background-color: #f5f5f5;

    ion-row:first-child {
      padding: 0;
    }

    .product_img {
      padding: 0;
    }

    .mt {
      margin-top: 8px;
    }

    ion-row {
      padding: 0 20px;
      background-color: #ffffff;
      ion-col {
        padding: 0;

        &.detail_con {
          padding-top: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid #eaeaea;

          strong {
            font-size: 20px;
            font-weight: bold;
            color: #262626;
          }

          p {
            font-size: 14px;
            font-weight: 500;
            color: #767676;
          }

          .row_price {
            margin-top: 10px;
            display: flex;
            align-items: baseline;

            .normal {
              // display: inline-block;
              font-size: 16px;
              text-decoration: line-through;
              margin-right: 10px;

              > ion-text {
                color: #999999;
              }

              span {
                font-weight: 500;
              }
            }

            .sale {
              font-size: 24px;
              font-weight: bold;
              color: #262626;
              text-decoration: none;

              > ion-text {
                color: #262626;
              }
            }

            .out_of {
              font-size: 24px;
              font-weight: bold;
              color: #262626;
              text-decoration: none;

              > ion-text {
                color: #b2b2b2;
              }
            }
          }
        }

        &.product_info {
          padding-top: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid #eaeaea;
          ul {
            font-size: 14px;
            font-weight: 500;
            color: #767676;

            li {
              margin-top: 5px;
              display: flex;
              width: 100%;
              &:first-child {
                margin-top: 0;
              }

              span {
                width: 45px;
                min-width: 45px;
              }

              em {
                margin-left: 10px;

                &.emphasis {
                  color: #262626;
                }
              }
            }
          }
        }

        &.con_box3 {
          padding-bottom: 30px;
          .tit {
            display: block;
            padding: 20px 0;
            font-size: 16px;
            font-weight: bold;
            color: #262626;
          }

          .tit + ion-list {
            padding: 0;
            > ion-row {
              padding: 0;
              margin-top: 10px;

              &:first-child {
                margin-top: 0;
              }
            }
          }

          .btn_cart {
            width: 70%;
            margin: 20px auto 0 auto;
            font-size: 14px;
            font-weight: 500;
            --border-radius: 50px;
            --background: #805844;
            --color: #fff;
            --background-hover: none;
            --background-activated: #805844;
            --color-activated: #fff;
          }
        }

        &.con_box4 {
          position: relative;
          padding-top: 10px;

          &::after {
            content: '';
            position: absolute;
            left: -20px;
            right: -20px;
            border-bottom: 1px solid #eaeaea;
            z-index: 10;
          }

          .info_tab {
            font-size: 16px;
            font-weight: 500;

            ion-segment-button {
              --color: #262626;
              --color-activated: #262626;
              --color-checked: #e0644a;
              --color-hover: #262626;
              --background-hover: #262626;
              color: #262626;

              &.segment-button-checked {
                > ion-label {
                  border-bottom: none;
                }
              }

              &::part(native) {
                color: #262626;
              }

              ion-label {
                span {
                  font-size: 14px;
                  font-weight: 500;
                  color: #767676;
                }
              }
            }
          }
        }
      }

      &.tab_con {
        position: relative;
        width: 100%;
        padding: 0;
      }

      &.product_list {
        padding: 0 0 0 20px;
        margin-top: 8px;
        > ion-col {
          padding: 40px 0;
          ion-row {
            padding: 0;
          }
          strong {
            display: inline-block;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: bold;
            color: #262626;
          }
        }

        ion-card {
          width: 100%;
        }
      }
    }
  }

  .footer_box {
    ion-row {
      padding: 0;
    }
  }

  .innerWrap {
    position: fixed;
    width: 100%;
    height: 286px;
    bottom: 0;
    padding: 0 20px;
    border-radius: 30px 30px 0 0;
    box-shadow: 2px 3px 8px 7px rgb(0 0 0 / 20%);
    transition: all 0.5s;
    background-color: #fff;
    z-index: 11;

    &.down {
      height: 50px;
    }

    .orderCloseBtn {
      position: absolute;
      top: 5px;
      left: 50%;
      width: 38px;
      height: 38px;
      padding: 0;
      transform: translate(-50%);
      z-index: 20;

      button {
        padding: 0;
        width: 38px;
        height: 38px;
        font-size: 0;
        vertical-align: top;
        background-color: transparent;
        color: transparent;
        &::before {
          content: '';
          position: absolute;
          left: 5px;
          top: 0;
          width: 20px;
          height: 20px;
          border-top: 7px solid #b2b2b2;
          border-right: 7px solid #b2b2b2;
          transform: rotate(135deg);
        }
      }

      &.btn_open {
        button {
          &::before {
            top: 10px;
            transform: rotate(-45deg);
          }
        }
      }
    }

    .orderTxt {
      position: relative;
      padding-top: 60px;
      div {
        span {
          font-size: 12px;
          font-weight: 500;
          color: #b2b2b2;
        }

        span + span {
          margin-left: 5px;
          &::before {
            content: '|';
            margin-right: 5px;
          }
        }
      }

      strong {
        display: inline-block;
        height: 50px;
        //overflow-y: scroll;
        font-size: 24px;
        font-weight: bold;
        color: #262626;
      }

      .orderPrice {
        font-size: 18px;
        font-weight: 500;
        color: #262626;
        #total_amount {
          font-size: 18px;
          font-weight: bold;
          color: #262626;
        }
        .outOf {
          color: #b2b2b2;
        }
      }
    }

    .orderCount {
      position: absolute;
      right: 0;
      bottom: -20px;
      width: 134px;
      height: 46px;
      padding: 0 44px;
      border: 1px solid #eaeaea;

      button {
        position: absolute;
        top: 0;
        width: 45px;
        height: 100%;
        font-size: 0;
        color: transparent;

        &.btnMinus {
          left: 0;
          border-right: 1px solid #eaeaea;
          background-color: #fff;

          &::after {
            content: '-';
            font-size: 18px;
            font-weight: bold;
            color: #262626;
          }

          &:disabled::after {
            color: #b2b2b2;
          }
        }

        &.btnPlus {
          right: 0;
          border-left: 1px solid #eaeaea;
          background-color: #fff;

          &::after {
            content: '+';
            font-size: 18px;
            font-weight: bold;
            color: #262626;
          }

          &:disabled::after {
            color: #b2b2b2;
          }
        }
      }

      .number {
        width: 100%;
        height: 100%;
        border: none;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        vertical-align: middle;
        border: none;
        outline: none;
        color: #262626;

        &:disabled {
          color: #b2b2b2;
        }
      }
    }

    .btn_box {
      padding-top: 30px;
      padding-bottom: 50px;
      ion-button {
        display: inline-block;
        margin: 0;
        padding: 0;
        width: 50%;
        height: 50px;
        --background: #e0644a;

        &:first-child {
          --background: #805844;
        }
      }
    }
  }
}
