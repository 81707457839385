#inquiry_detail {
  ion-grid {
    ion-row {
      ion-col {
        .btn {
          margin:30px auto 60px auto;
          width: 220px;
          height:50px;
          font-size:14px;
          font-weight: 500;
          color: #fff;
          --box-shadow: none;
          --border-radius: 50px;
          --background:#805844;

          &::part(native) {
            padding:0 40px;
          }
        }
      }
    }
  }
}


