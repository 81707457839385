#delivery_info {
  ion-grid {
    padding:0;
    ion-col {
      padding:0;
    }
  }

  .deliveryService {
    text-align: center;
    ion-img {
      padding-top: 30px;
      display:inline-block;
    }
    .strong {
      font-weight: 700;
      font-size: 30px;
      color:#b4653e;
    }
    > p {
      font-weight: 500;
      font-size: 20px;
      color:#b4653e;
    }
  }

  .textBox {
    padding: 30px 0 70px 0;
    text-align: center;
    font-weight: 500;
    font-size:14px;
    color:#767676;
  }
}
