#delivery {
  ion-grid {
    padding:0;
    ion-row {
      padding-left:20px;
      padding-right:20px;

      ion-col {
        padding:0;

        .delivery_box {
          padding:20px 0;
          border-bottom: 1px solid #eaeaea;

          .txt {
            display: inline-block;
            position: relative;
            padding-left:10px;
            margin-left:10px;
            font-size: 12px;
            font-weight: 500;
            color: #e0644a;
            vertical-align: bottom;

            &::before {
              content: "";
              display: inline-block;
              position:absolute;
              left:0;
              top:50%;
              margin-top:-4px;
              width: 1px;
              height:8px;
              background:url('/assets/icon/line.jpg') no-repeat 0 0;
              background-size: 100%;
            }

            &.type2 {
              padding:6px 14px;
              border-radius: 50px;
              border:1px solid #eaeaea;
              color: #b2b2b2;
              vertical-align: middle;
              &::before {
                display: none;
              }
            }
          }

          ion-text {
            display: block;
            padding-left:25px;
            font-size:14px;
            font-weight: 500;
            color: #767676;
            span {
              display:block;
              margin-top:5px;

              &:first-child {
                margin-top:20px;
              }

              &.d_name {
                font-weight: bold;
              }
            }

            address {
              margin-top:14px;
            }

            address + address {
              margin-top:0px;
            }

            .btn_box {
              margin-top:20px;
              ion-button {
                height:40px;
                margin:0 0 0 5px;
                font-size: 14px;
                font-weight: 500;
                color: #b2b2b2;
                border:1px solid #eaeaea;
                --box-shadow: none;
                --background:transparent;

                &:first-child {
                  margin:0;
                }

                &::part(native) {
                  padding:0 25px;
                }
              }
            }
          }
        }

        .btn {
          margin:30px auto 60px auto;
          width: 220px;
          height:50px;
          font-size:14px;
          font-weight: 500;
          color: #fff;
          --box-shadow: none;
          --border-radius: 50px;
          --background:#805844;

          &::part(native) {
            padding:0 40px;
          }
        }
      }
    }

    label {
      width: 100%;
      padding-left:25px;
      font-size:16px;
      font-weight: bold;
      vertical-align: middle;
      color: #262626;
    }

    input[type='radio'] + label {
      padding-left:0;
    }


    input[type='radio'],
    input[type='radio']:checked {
      appearance: none;
      width: 20px;
      height: 20px;
      margin-right:5px;
      border-radius: 100%;
      border:1px solid #eaeaea;
      vertical-align: middle;
    }

    input[type='radio'] {
      background-color: transparent;
    }

    input[type='radio']:checked {
      position: relative;
    }

    input[type='radio']:checked:before {
      content: "";
      position:absolute;
      left:50%;
      top:50%;
      width: 10px;
      height: 10px;
      margin:-5px 0 0 -5px;
      border-radius: 100%;
      background-color:#e0644a;

    }

    .delivery_not_exist {
      padding:152px 0;

      ion-icon {
        margin-bottom: 10px;
      }
      ion-text {
        display: block;
        font-size: 14px;
      }
    }
  }
}
