/* common */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

html {font-size:10px; line-height:1.5;}
body {font-family: 'Noto Sans KR', sans-serif;}
table, caption, tbody, tfoot, thead, tr, th, td {margin:0; padding:0; background:transparent;}
table {border-collapse:collapse; border-spacing:0;}
caption, legend {visibility:hidden; overflow:hidden; width:0; height:0; font-size:0; line-height:0;}
ul, ol {list-style-type:none;}
a {text-decoration:none; color:#262626;}
i, em, address {font-style:normal;}


/* web font 적용*/
@font-face {
    font-family: 'Noto Sans KR';
    font-weight: 300;
    src: url('./style/fonts/NotoSansCJKkr-Light.otf') format('woff2'),
    url('./style/fonts/NotoSansCJKkr-Light.otf') format('woff'),
    url('./style/fonts/NotoSansCJKkr-Light.otf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-weight: 400;
    src: url('./style/fonts/NotoSansCJKkr-Regular.otf') format('woff2'),
    url('./style/fonts/NotoSansCJKkr-Regular.otf') format('woff'),
    url('./style/fonts/NotoSansCJKkr-Regular.otf') format('truetype');
}
@font-face {
    font-family: 'Noto Sans KR';
    font-weight: 500;
    src: url('./style/fonts/NotoSansCJKkr-Medium.otf') format('woff2');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    src: url('./style/fonts/NotoSansCJKkr-Bold.otf') format('woff2'),
    url('./style/fonts/NotoSansCJKkr-Bold.otf') format('woff'),
    url('./style/fonts/NotoSansCJKkr-Bold.otf') format('truetype');
}
