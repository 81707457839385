#cart {
  ion-card {
    display:inline-block;
    margin:0;
    width: 100%;
    box-shadow: rgb(0 0 0 / 12%) 0px 0px 0px;
    border-radius: 0;
    border-bottom: 1px solid #eaeaea;

    .cart_con {
      padding:0;

      > ion-row {
        padding:20px 0 0 0;
        .chk_box {
          position:relative;

          .remove {
            position:absolute;
            bottom:5px;
            right:0;
            font-size:14px;
            font-weight: 500;
            color:#b2b2b2;
          }

          .product_tit {
            display:block;
            width: 80%;
            height:24px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            color: #262626;
          }
        }
      }

      ion-row + ion-row {
        padding-bottom:20px;
        > ion-col {
          position: relative;

          ion-img {
            float:left;
            width: 120px;
            height:120px;
          }

          .price_box {
            float:left;
            margin-left:20px;
            .price {
              font-size:16px;
              font-weight: 500;
              color: #262626;
              > em {
                font-size:18px;
                font-weight: bold;
              }
            }

            ion-text + ion-text {
              > p {
                font-size: 16px;
                font-weight: 500;
                &.free {
                  color: #6ca437;
                }
                &.single {
                  color: #e0644a;
                }
              }
            }

            .out_of {
              font-size: 18px;
              font-weight: 500;
              color: #b2b2b2;
            }
          }

          &::after {
            content: '';
            display: block;
            clear: both;
          }

          .orderCount {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 134px;
            height: 46px;
            padding: 0 44px;
            border: 1px solid #eaeaea;

            button {
              position: absolute;
              top: 0;
              width: 45px;
              height: 100%;
              font-size: 0;
              color: transparent;

              &.btnMinus {
                left: 0;
                border-right: 1px solid #eaeaea;

                &::after {
                  content: '-';
                  font-size: 18px;
                  font-weight: bold;
                  color: #262626;
                }

                &:disabled::after {
                  color: #b2b2b2;
                }
              }

              &.btnPlus {
                right: 0;
                border-left: 1px solid #eaeaea;

                &::after {
                  content: '+';
                  font-size: 18px;
                  font-weight: bold;
                  color: #262626;
                }

                &:disabled::after {
                  color: #b2b2b2;
                }
              }
            }

            .number {
              width:100%;
              height:100%;
              border:none;
              font-size:18px;
              font-weight: bold;
              text-align:center;
              vertical-align:middle;
              border: none;
              outline:none;
              color:#262626;

              &:disabled {
                color: #b2b2b2;
              }
            }
          }
        }
      }
    }
  }
}
