#inquiry {
  ion-grid {
    padding:0;

    ion-row {
      padding:0 20px;

      &.text_box {
        ion-textarea {
          border: 1px solid #eaeaea;
          height: 250px;
          font-size: 14px;
          font-weight: 500;
        }
      }

      &.btn_box {
        padding:20px 0 60px 0 ;
        margin:0 20px;
        ion-col {
          padding:0;
          ion-button {
            height:50px;
            --background:#e0644a;
            font-size:14px;
            color: #fff;
            --box-shadow: none;
          }
        }
      }
    }
  }
}
