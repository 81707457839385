#product_detail {
  .con_type1 {
    img {
      display: block;
      width: 100%;
    }

    dl {
      text-align: center;
      padding-bottom: 50px;
      dt {
        padding-top: 30px;
        strong {
          font-size: 20px;
          font-weight: bold;
          color: #262626;
        }
      }

      dd {
        p {
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          color: #767676;
        }

        ion-img {
          margin-top: 10px;
          &.logo {
            display: inline-block;
          }
        }
      }
    }

    > .toastui-editor-contents {
      img {
        display: initial;
        width: initial;
      }

      dl {
        text-align: initial;
        padding-bottom: initial;
        dt {
          padding-top: initial;
          strong {
            font-size: initial;
            font-weight: initial;
            color: initial;
          }
        }

        dd {
          p {
            display: initial;
            font-size: initial;
            font-weight: initial;
            color: initial;
          }

          ion-img {
            margin-top: initial;
            &.logo {
              display: initial;
            }
          }
        }
      }
    }
  }
}
