.wrap {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0.7;
  filter: alpha(opacity=70);
  z-index: 30;

  &.on {
    display: block;
  }
}

.cart_modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 31;
  text-align: center;

  &.on {
    display: block;
  }

  p {
    padding: 60px 0 30px 0;
    font-size: 16px;
    font-weight: 500;
    span {
      display: block;
      color: #ffffff;
      &.product_item {
        font-size: 18px;
        font-weight: bold;
        text-decoration: underline;
        color: #ffdf2e;
      }
    }
  }

  .img_box {
    padding: 0 67px;
    ion-img {
      display: inline-block;
      width: 100%;
    }
  }

  .btn_box {
    > ion-button {
      display: inline-block;
      width: 220px;
      height: 50px;
      margin: 30px 0 0 0;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      --box-shadow: none;
      --background: #e0644a;
    }
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    margin: 0;
    background: url('/assets/icon/btn_close.png') no-repeat 0 0;
    background-size: 100%;
    cursor: pointer;
    z-index: 33;
    --box-shadow: none;

    &::part(native) {
      background: none;
      font-size: 0;
    }
  }
}
