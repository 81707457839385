#signup_useful {
  ion-grid {
    padding:0;
    ion-col {
      padding:0;
    }
  }

  .signService {
    text-align: center;
    ion-img {
      padding-top: 30px;
      display:inline-block;
    }
    .strong {
      font-weight: 700;
      font-size: 30px;
      color:#b4653e;
    }
    > p {
      font-weight: 500;
      font-size: 20px;
      color:#b4653e;
    }
    .button-solid {
      --background: #e0644a;
      --color: #ffffff;
      --box-shadow: none;
    }
    ion-button {
      margin : 30px 20px 30px 20px;
      height: 50px;
      --border-radius: 0px;
    }
  }

  .line {
    height: 0;
    border: solid 1px #eaeaea;
    margin : 0 20px 30px 20px;
  }

  .whiteThem {
    .button-solid {
      --background: #ffffff;
      --color: #262626;
      --box-shadow: none;
    }
    ion-button {
      position: relative;
      margin : 0 20px 10px 20px;
      height: 50px;
      --border-radius: 0px;
      border: solid 1px #eaeaea;

      &::before {
        content: "";
        position: absolute;
        left: 30px;
        top: 50%;
        margin-top:-9px;
        width: 15px;
        height: 18px;
        background-image: url("/assets/icon/kakao.png");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 0 0;
        z-index: 10;
      }

      &.naver::before {
        background-image: url("/assets/icon/naver.png");
      }

      &.google::before {
        background-image: url("/assets/icon/google.png");
      }

      &.apple::before {
        background-image: url("/assets/icon/apple.png");
      }
    }
    ion-img {
      width: 18px;
      height: 17px;
    }
    .apple {
      margin-bottom: 60px;
    }
  }

  .colorThem {
    .button-solid {
      --background: #fee500;
      --color: #262626;
      --box-shadow: none;
    }
    .naver.button-solid {
      --background: #03c75a;
      --color: #ffffff;
    }
    .google.button-solid {
      --background: #ffffff;
      --color: #262626;
    }
    .apple.button-solid {
      --background: #050708;
      --color: #ffffff;
    }
    .apple {
      margin-bottom: 60px;
    }
    ion-button {
      position: relative;
      margin : 0 20px 10px 20px;
      height: 50px;
      --border-radius: 0px;
      border: solid 1px #eaeaea;

      &::before {
        content: "";
        position: absolute;
        left: 30px;
        top: 50%;
        margin-top:-9px;
        width: 15px;
        height: 18px;
        background-image: url("/assets/icon/kakao.png");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 0 0;
        z-index: 10;
      }

      &.naver::before {
        background-image: url("/assets/icon/naver_white.png");
      }

      &.google::before {
        background-image: url("/assets/icon/google.png");
      }

      &.apple::before {
        background-image: url("/assets/icon/apple_white.png");
      }
    }
    ion-img {
      width: 18px;
      height: 17px;
    }
  }

}
