#search_page {
  ion-grid {
    padding:0;

    ion-row {
      padding:0 20px;
      ion-col {
        padding:0;
        margin:0;

        .custom_search {
          position: relative;
          margin:30px 0 20px 0;

          ion-input {
            border:1px solid #262626;
            border-radius: 50px;
            font-size: 16px;
            font-weight: 500;

            --padding-top:20px;
            --padding-bottom:20px;
            --padding-start: 20px;
            --padding-end: 20px;
            --placeholder-color: #b2b2b2;
          }

          ion-button {
            position: absolute;
            right:20px;
            top:50%;
            width: 20px;
            height:20px;
            margin:0;
            background-image: url("/assets/icon/ico_search.png");
            background-size: 100%;
            background-position: 0 0;
            transform:translate(0,-50%);
            z-index: 10;
          }
        }
        .segment_wrap {
          &::-webkit-scrollbar {
            display: none;
          }
          ion-segment-button {
            margin-right:8px;
            font-size:14px;
            font-weight: bold;
            height:26px;
            min-height:26px;
            --border-radius: 50px;
            --border-color:#eaeaea;
            --border-width:1px;
            --border-style:solid;
            --color: #767676;
            --background-checked: #805844;
            --color-checked: #ffffff;
            --indicator-transition:none;
            --indicator-color:none;

            &.segment-button-checked {
              > ion-label {
                border-bottom:none;
              }
            }
          }
        }

      }

      &.tag {
        padding:0 14px 0 20px;
      }


      &.txt {
        p {
          padding:46px;
          font-size: 16px;
          font-weight: 500;
          color: #767676;
        }
      }

      &.search_result {
        .result_tit {
          padding:50px 0 20px 0;
          font-size:16px;
          font-weight: bold;
          color: #262626;
        }
        ion-row {
          padding:0;
        }
        ion-list {
          margin-top:20px;
          padding:0;
          ion-card + ion-card:nth-child(even) {
            margin-left:4%;
          }
        }
      }

      &.open {
        > ion-col {
          position: relative;
          padding:0;

          > ion-button {
            margin:0;
            height:40px;
            font-size:14px;
            font-weight: 500;
            --background:transparent;
            --color: #b2b2b2;
            position:relative;

            .arrow {
              position: relative;

              &::after {
                position: absolute;
                right: -10px;
                top: 5px;
                content: '';
                width: 4px;
                height: 4px;
                border-top: 1px solid #b2b2b2;
                border-right: 1px solid #b2b2b2;
                transform: rotate(400deg);
              }
            }

            &::part(native) {
              border:1px solid #eaeaea;
              --box-shadow:none
            }
          }
        }
      }

    }

    .search_list {
      padding:50px 20px 60px 20px;

      ion-item {
        --padding-start: 0;
      }
      ion-list-header {
        padding-left:0;
        font-size: 16px;
        font-weight: bold;
        color: #262626;
      }

      .keyword {
        font-size:16px;
        font-weight: 500;
        color: #767676;
      }
    }
  }
}
