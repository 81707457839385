ion-segment {
  font-size:13px;
  background-color:#fff;

  ion-segment-button {
    --color: #8d8d8d;
    --color-checked: none;

    &.segment-button-checked {
      > ion-label {
        border-bottom:1px solid #383838;
      }
    }
  }
}
