#recipe_detail {
  ion-grid {
    padding: 0;
    ion-row {
      ion-col {
        padding:0;

        .recipe_tit {
          display: block;
          padding:20px 0 30px 0;
          border-bottom: 1px solid #eaeaea;
          font-size:20px;
          font-weight: bold;
          color: #262626;
        }

        &.product_info {
          padding-top:20px;
          padding-bottom: 20px;
          border-bottom:1px solid #eaeaea;
          ul {
            font-size: 14px;
            font-weight: 500;
            color: #767676;

            li {
              margin-top:20px;
              &:first-child {
                margin-top:0;
              }

              span {
                display:inline-block;
                width:13%;
                vertical-align: top;
              }

              em {
                display: inline-block;
                width:70%;
                margin-left:5%;
                color: #262626;
              }
            }
          }
        }

        &.recipe_con {
          dl {
            padding-bottom:30px;
            font-size:14px;
            font-weight: 500;
            color: #767676;
            dt {
              padding-top:30px;
            }
            dd {
              margin-top:20px;
              color: #262626;
            }
          }
        }

      }
    }

    ion-row:last-child{
      padding-bottom:60px;
    }
  }

  .pd {
    padding:0 20px;
  }

}
