#inquiry_detail{
  ion-grid {
    ion-row {
      &.inquiry {
        font-weight: 500;
        font-size: 14px;
        border-bottom: 1px solid #eaeaea;
      }
      ion-col {
        div {
          &.complete_box {
            padding:30px 20px 0 20px;
            border-bottom: 1px solid #eaeaea;
          }
          &.progress_box {
            padding:30px 20px 0 20px;
          }
          &.answer_box {
            color: #767676;
            padding:30px 40px;
          }
          &.state_box {
            padding:20px 0 30px 0;
            span:first-child {
              padding-right: 10px;
              font-size: 12px;
              color: #e0644a;
            }
            span:last-child {
              font-size: 12px;
              color: #b2b2b2;
            }
          }
          &.add_box {
            padding: 30px 0 20px 0;
            .col_span {
              display:inline-block;
              vertical-align: middle;
            }

            ion-img {
              width:100px;
              height:100px;
            }

            .price_box {
              margin-left:20px;
              span {
                font-size: 12px;
                font-weight: 500;
                color: #b2b2b2;
              }
              span + span {
                margin-left:5px;
                &::before {
                  content: "|";
                  margin-right: 5px;
                }
              }

              strong {
                display: block;
                font-size:14px;
                font-weight: bold;
                color: #262626;
              }

              .price {
                font-size: 16px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}