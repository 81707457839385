#gift {
  ion-grid {
    padding:0;
    ion-col {
      padding:0;
    }
  }

  .comingsoon {
    margin-bottom: 60px;
    text-align: center;
    ion-img {
      display:inline-block;
    }
  }

  .giftService {
    text-align: center;
    ion-img {
      display:inline-block;
      padding-top: 30px;
    }
    .strong {
      font-weight: 700;
      font-size: 30px;
      color:#b4653e;
    }
    > p {
      font-weight: 500;
      font-size: 20px;
      color:#b4653e;
    }
  }

  .textBox {
    padding: 20px 0 30px 0;
    text-align: center;
    font-weight: 500;
    font-size:14px;
    color:#767676;
  }

  .giftBox {
    padding-left: 0px;
    margin-bottom: 40px;
    ion-img {
    }
  }

  .business {
    padding-left: 0px;
    ion-img {
    }
  }

  .subTitle {
    margin-bottom: 15px;
    text-align: center;
    > p {
      font-size: 20px;
      font-weight: 400;
      color: #b46532;
    }
  }

  .ellipse {
    margin-bottom: 40px;
    > div {
      text-align: center;
      display: flex;
      height: 80px;
      border-radius: 50px;
      border: solid 2px #b4653e;
      background-color: #fff8f5;
      margin-right: 22px;
      margin-left: 22px;
      > p {
        font-size:24px;
        font-weight: 700;
        color: #b4653e;
        margin:auto;
      }
    }
  }
}
