#product_detail {
  .con_type2 {
    ul {
      padding: 30px 0;
      li {
        &:first-child {
          margin-top: 0;
        }
        margin-top: 3px;
        font-size: 14px;
        font-weight: 500;
        span {
          display: inline-block;
          width: 30%;
          color: #767676;
        }
        em {
          color: #262626;
        }
      }
    }

    dl {
      padding-top: 30px;
      padding-bottom: 30px;
      border-top: 1px solid #eaeaea;
      font-size: 14px;
      font-weight: 500;

      dt {
        color: #767676;
      }
      dd {
        margin-top: 20px;
        p {
          margin-top: 30px;
        }

        .btn_info {
          margin: 30px 0 0 0;
          width: 220px;
          height: 50px;
          font-size: 14px;
          font-weight: 500;
          --background: #fff;
          --color: #b4653e;
          --background-hover: none;
          --background-activated: #fff;
          --color-activated: #b4653e;

          div {
            position: relative;
            line-height: 18px;

            &::before {
              content: '';
              position: absolute;
              width: 17px;
              height: 18px;
              left: -23px;
              background: url('/assets/icon/ico_question.png') no-repeat 0 0;
            }
          }

          &::part(native) {
            padding: 15px 0;
            border: 1px solid #b4653e;
            border-radius: 50px;
          }
        }
      }

      &:last-child {
        padding-bottom: 60px;
      }
    }

    > .toastui-editor-contents {
      ul {
        padding: initial;
        li {
          &:first-child {
            margin-top: initial;
          }
          margin-top: initial;
          font-size: initial;
          font-weight: initial;
          span {
            display: initial;
            width: initial;
            color: initial;
          }
          em {
            color: initial;
          }
        }
      }

      dl {
        padding-top: initial;
        padding-bottom: initial;
        border-top: initial;
        font-size: initial;
        font-weight: initial;

        dt {
          color: initial;
        }
        dd {
          margin-top: initial;
          p {
            margin-top: initial;
          }

          .btn_info {
            margin: initial;
            width: initial;
            height: initial;
            font-size: initial;
            font-weight: initial;
            --background: initial;
            --color: initial;
            --background-hover: initial;
            --background-activated: initial;
            --color-activated: initial;

            div {
              position: initial;
              line-height: initial;

              // &::before {
              //   content: '';
              //   position: absolute;
              //   width: 17px;
              //   height: 18px;
              //   left: -23px;
              //   background: url('/assets/icon/ico_question.png') no-repeat 0 0;
              // }
            }

            &::part(native) {
              padding: initial;
              border: initial;
              border-radius: initial;
            }
          }
        }

        &:last-child {
          padding-bottom: initial;
        }
      }
    }
  }
}
