#delivery {
  ion-item {
    font-weight: 700;
    font-size:16px;
    &::part(native) {
      padding-top:5px;
      padding-bottom:15px;
    }
    ion-label {
      position: relative;
    }
  }
  .content {
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    --background: #fafafa;
    font-weight: 500;
    font-size:14px;
    color: #767676;
    p {
      padding:20px 0 30px 0;
    }
  }

  .subHeader {
    font-weight: 500;
    font-size:15px;
    color: #b2b2b2;
    position: absolute;
    z-index: 0;
    padding: 25px 0 0 0;
  }
}
